import React from 'react';
import { Address } from '@conventic-web/internal.cvt.basic.address';
import { Box } from '@mui/material';
/*---Components---*/
import PageblockStandort from '../pageBlockStandort';

/*---Queries---*/
import AddressDataQuery from '../../utility/data/address/address-query';

/*---Images---*/
import {IconComponents} from "../../global/sharedObjects"

function CardKontakteStandorte() {
  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingLeft: '40%',
      alignItems: 'flex-start',
      width: '100%',
      [`@media(max-width: 1818px)`]: {
        paddingLeft: '0%',
        justifyContent: 'center',
      },
    },
  } as const;
  const data = AddressDataQuery();
  const addressData = data.nodes[0].frontmatter;
  const PhotoSize = 15;

  return (
    <Box component="div" sx={styles.root}>
      <PageblockStandort
        photoSize={PhotoSize}
        photo={addressData.utilityAddressBonn.image.publicURL}
        icon={IconComponents.LocationOn}
        headline="Standort Bonn"
      >
        <Address
          street={addressData.utilityAddressBonn.street}
          zipcode={addressData.utilityAddressBonn.zipcode}
          place={addressData.utilityAddressBonn.place}
          country={addressData.utilityAddressBonn.country}
          fon={addressData.utilityAddressBonn.fon}
        />
      </PageblockStandort>

      <PageblockStandort
        photoSize={PhotoSize}
        photo={addressData.utilityAddressBraunschweig.image.publicURL}
        icon={IconComponents.LocationOn}
        headline="Standort Braunschweig"
      >
        <Address
          street={addressData.utilityAddressBraunschweig.street}
          zipcode={addressData.utilityAddressBraunschweig.zipcode}
          place={addressData.utilityAddressBraunschweig.place}
          country={addressData.utilityAddressBraunschweig.country}
          fon={addressData.utilityAddressBraunschweig.fon}
        />
      </PageblockStandort>
    </Box>
  );
}
/**
 * @description Returns the current location hexagons.
 */

export default CardKontakteStandorte;
