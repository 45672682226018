import React from 'react';
import styled from '@emotion/styled';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';

/*---CVT---*/
import { CvtColorSchema } from '@conventic-web/internal.global';

/*---Material UI---*/
import { TextField } from '@mui/material';

/*---Styled Components - Implementation---*/
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HPot = styled.div`
  visibility: hidden;
  position: absolute;
`;

const SubmitBtn = styled.div`
  color: ${CvtColorSchema.green};
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 0;

  ${(props) => {
    if (props.disabledBtn) {
      return `
        opacity: 0.4;
        cursor: not-allowed;
      `;
    }
  }};
`;

class CardKontakt extends React.Component {
  state = {
    UsersStateName: '',
    UsersStateEmail: '',
    UsersStateBetreff: '',
    UsersStateNachricht: '',
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmitForm() {
    document.forms['Kontakt'].submit();
  }

  render() {
    return (
      <Form
        data-netlify="true"
        method="POST"
        name="Kontakt"
        netlify-honeypot="bot-field"
        action={SYSTEM_STRINGS.LINKS.INTERN.KONTAKT_SUCCESS}
      >
        {/*Only needed for SSGs, value must be name of form */}
        <input type="hidden" name="form-name" value="Kontakt" />
        {/* HPot = Netlify uses this field to see that it is a bot and puts it into the spam folder*/}
        <HPot>
          <TextField
            id="InputPot"
            type="text"
            name="bot-field"
            data-label="Ihr Name"
          />
        </HPot>
        <TextField
          id="form_name"
          label="Ihr Name"
          variant="standard"
          name="UsersStateName"
          type="text"
          multiline
          required
          value={this.state.UsersStateName}
          onChange={this.handleInputChange}
        />
        <TextField
          id="form_email"
          label="Ihre E-Mail Adresse"
          variant="standard"
          type="email"
          name="UsersStateEmail"
          multiline
          required
          value={this.state.UsersStateEmail}
          onChange={this.handleInputChange}
        />

        <TextField
          id="form_betreff"
          label="Betreff"
          variant="standard"
          type="text"
          name="UsersStateBetreff"
          multiline
          required
          value={this.state.UsersStateBetreff}
          onChange={this.handleInputChange}
        />
        <TextField
          id="form_nachricht"
          label="Ihre Nachricht"
          variant="standard"
          type="text"
          name="UsersStateNachricht"
          multiline
          required
          value={this.state.UsersStateNachricht}
          onChange={this.handleInputChange}
        />

        {this.state.UsersStateName !== '' &&
        this.state.UsersStateEmail !== '' &&
        this.state.UsersStateBetreff !== '' &&
        this.state.UsersStateNachricht !== '' ? (
          <SubmitBtn onClick={this.handleSubmitForm}>Absenden</SubmitBtn>
        ) : (
          <SubmitBtn disabledBtn={true}>Absenden</SubmitBtn>
        )}
      </Form>
    );
  }
}

/**
 * @description Returns the contact-input-fiels.
 * @see /Kontakt.js
 */

export default CardKontakt;
