import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { useLocation } from '@reach/router';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import CardKontakteInput from '../../components/Kontakt_comps/cardKontakteInput';
import CardKontakteStandorte from '../../components/Kontakt_comps/cardKontakteStandorte';
import { LinkComponent } from '../../components/linkComponent';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const KontaktPageTemplate = ({
  title,
  subtitle,
  image,
  kontaktForm,
  kontaktStandorte,
  kontaktOrganisation,
}: {
  title: string;
  subtitle: string;
  image: any;
  kontaktForm: any;
  kontaktStandorte: any;
  kontaktOrganisation: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: 'white',
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: 'white',
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h1>{kontaktForm.headline}</h1>
      <h3>{kontaktForm.subheadline}</h3>
      <hr />
      <div style={{ backgroundColor: 'yellow' }}>
        <h1>{kontaktStandorte.headline}</h1>
      </div>

      <hr />
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!kontaktOrganisation.image.childImageSharp
              ? kontaktOrganisation.image.childImageSharp.fluid.src
              : kontaktOrganisation.image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1>{kontaktOrganisation.headline}</h1>
        <h3>{kontaktOrganisation.subheadline}</h3>
        <p>{kontaktOrganisation.content}</p>
      </div>
      <hr />
      <footer
        style={{
          backgroundColor: '#eeeeee',
        }}
      >
        Footer
      </footer>
    </section>
  );
};

KontaktPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  kontaktForm: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
  }),
  kontaktStandorte: PropTypes.shape({
    headline: PropTypes.string,
  }),
  kontaktOrganisation: PropTypes.shape({
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
  }),
};

const KontaktPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.white}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
      />
      <BlockStandard
        headline={frontmatter.kontaktForm.headline}
        subheadline={frontmatter.kontaktForm.subheadline}
        bgColor={CvtColorSchema.white}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={7}
        padding={PaddingObj.PaddingMore}
        color={CvtColorSchema.fontDarkGray}
      >
        <CardKontakteInput />
      </BlockStandard>
      <BlockStandard
        headline={frontmatter.kontaktStandorte.headline}
        bgColor={CvtColorSchema.yellow}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={6}
        padding={PaddingObj.PaddingMore}
        color={CvtColorSchema.fontDarkGray}
      >
        <CardKontakteStandorte />
      </BlockStandard>
      <BlockStandard
        headline={frontmatter.kontaktOrganisation.headline}
        subheadline={frontmatter.kontaktOrganisation.subheadline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={5}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
        bgImage={
          !frontmatter.kontaktOrganisation.image.childImageSharp &&
          frontmatter.kontaktOrganisation.image.extension === 'svg'
            ? frontmatter.kontaktOrganisation.image.publicURL
            : null
        }
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.kontaktOrganisation.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.UNTERNEHMEN}>
          {SYSTEM_STRINGS.GENERAL.WORDS.MORE}
        </LinkComponent>
      </BlockStandard>
    </Layout>
  );
};

KontaktPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default KontaktPage;

export const kontaktPageQuery = graphql`
  query KontaktPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        seoKeywords
        pageDescription
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }

        kontaktForm {
          headline
          subheadline
        }
        kontaktStandorte {
          headline
        }
        kontaktOrganisation {
          headline
          subheadline
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          content
        }
      }
    }
  }
`;
